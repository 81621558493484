import React, {useState} from 'react'
import { Link } from 'gatsby'
import { Viewer, KmlDataSource, ImageryLayer, CameraFlyTo } from "resium"
import { Ion, Cartesian3, UrlTemplateImageryProvider, Credit } from 'cesium'
// import totalCases from '../Data/small_data_total.czml'
// import totalDeaths from '../Data/small_data_deaths.czml'
// import totalCasesPM from '../Data/small_data_total_pm.czml'
// import totalDeathsPM from '../Data/small_data_deaths_pm.czml'
const totalCases = '/kml/sample.kml'
const totalDeaths = '/kml/sample.kmz'
const totalCasesPM = '/kml/北海道函館市_H26小売業販売額×公共交通利用圏.kmz'
const totalDeathsPM = '/kml/sample.kml'
// import totalCases from '/kml/sample.kml'
// import totalDeaths from '/kml/sample.kmz'
// import totalCasesPM from '/kml/北海道函館市_H26小売業販売額×公共交通利用圏.kmz'
// import totalDeathsPM from '/kml/sample.kml'

// import {
//   ArrowLeftCircle,
//   Compass
// } from 'react-feather'
// import ReactTooltip from 'react-tooltip';

// import Box from 'react-bulma-components/lib/components/box'
// import Columns from 'react-bulma-components/lib/components/columns'
// import Modal from 'react-bulma-components/lib/components/modal'
// import Button from 'react-bulma-components/lib/components/button'
// import { div, div, div, Input, Checkbox} from 'react-bulma-components/lib/components/form';

const imageryProvider = new UrlTemplateImageryProvider({
  url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
})

export default function Visualization() {
  Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_API_KEY
  
  const [showTotalCases, setShowTotalCases] = useState(true)
  const [showTotalDeaths, setShowTotalDeaths] = useState(false)
  const [showTotalCasesPM, setShowTotalCasesPM] = useState(false)
  const [showTotalDeathsPM, setShowTotalDeathsPM] = useState(false)

  const [customLatitude, setCustomLatitude] = useState(35)
  const [customLongitude, setCustomLongitude] = useState(135)
  const [showModal, setShowModal] = useState(false)

  const toolBoxStyle = {
    width: '20%',
    color: 'white',
    top: '0px',
    left: '75%',
    zIndex: 2,
    position: 'relative'
  }

  function onChangeLatitude(value) {
    if (isNaN(value)) {
      setCustomLatitude(0)
    } else {
      setCustomLatitude(value)
    }
  }

  function onChangeLongitude(value) {
    if (isNaN(value)) {
      setCustomLongitude(0)
    } else {
      setCustomLongitude(value)
    }
  }


  return (
    <div>
      <Link to='/home2'>
        <div 
          style={{ marginTop: '4%', marginLeft: '4%', maxWidth: '10%', position: 'relative', zIndex: 3}} 
          className='is-transparent-more is-text'
        >
          <button src="/home2">Go Back</button>
        </div>
      </Link>
        
      {/* <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        closeOnBlur={true}
      >
        <Modal.Content style={{color: 'white', width: '70%',}}>
          <h1>Social Area Analyze Systemへようこそ</h1>
          <h2>この画面の使い方</h2>
          <h3>アニメーションで確認する</h3>
          左側のチェックボックスから、アニメーションを選択してください<br/>
          大まかな変化の概要を確認しましょう<br/>
          <h3>閲覧する年次の選択</h3>
          左側のチェックボックスから、詳細に見たい年次を選択してください<br/>
          選択した年次のデータのみが可視化されます<br/>

        </Modal.Content>
      </Modal> */}

      <div className='is-transparent' style={toolBoxStyle}>
        {/* <Button 
          data-tip data-for='help'
          className='is-transparent-more is-text' 
          style={{ marginTop: '1%', marginLeft: '80%', maxWidth: '40%' }} 
          onClick={() => setShowModal(true)}
        >
          <Compass size={28} color='black' />
        </Button>
        <ReactTooltip id='help' place='top' effect='solid'>
          Instructions
        </ReactTooltip> */}
        <div>
          <div>経年変化を追う</div>
            <div>
            <label>Show CZML</label>
            <input type="checkbox" id="showCheckbox" /> 
            <label>Show CZML</label>
              <input type="checkbox" 
                name="totalCases" 
                onChange={(e) => setShowTotalCases(e.target.checked)} 
                checked={showTotalCases}
              />
            <label>Show CZML</label>
              <input type="checkbox" 
                name="totalDeaths" 
                onChange={(e) => setShowTotalDeaths(e.target.checked)} 
                checked={showTotalDeaths}
              />
            <label>Show CZML</label>
              <input type="checkbox" 
                name="totalCasesPM" 
                onChange={(e) => setShowTotalCasesPM(e.target.checked)} 
                checked={showTotalCasesPM}
              />
              </div>
        </div>
        <div>
            <div>
            <label>Show CZML</label>
              <input type="checkbox" 
                name="totalDeaths" 
                onChange={(e) => setShowTotalDeathsPM(e.target.checked)} 
                checked={showTotalDeathsPM}
              />
            </div>
        </div>

        
        <div style={{marginTop: '3%'}}>Legend (# of People)</div>
        <div className='gradient'></div>
        <p style={{wordSpacing: '40px'}}>50 200 2000 3000+</p>
      </div>

      <Viewer style={{
        // position: 'absolute',
        margin:0,
        height:"500px",
        // top: "200px",
        // left: "50%",
        // right: 0,
        // bottom: 0,
        zIndex: 0,
      }}
      imageryProvider={false}
      baseLayerPicker={false}
      fullscreenButton={true}
      homeButton={true}
      sceneModePicker={false}
      selectionIndicator={true}
      timeline={false}
      animation={false}
      geocoder={false}
      >
        <ImageryLayer imageryProvider={imageryProvider} />
        <KmlDataSource data={totalCases} show={showTotalCases}/>
        <KmlDataSource data={totalDeaths} show={showTotalDeaths}/>
        <KmlDataSource data={totalCasesPM} show={showTotalCasesPM}/>
        <KmlDataSource data={totalDeathsPM} show={showTotalDeathsPM}/>
        <CameraFlyTo duration={5} destination={Cartesian3.fromDegrees(customLongitude, customLatitude, 6000000)} />
        {/* <CameraFlyTo duration={5} destination={Cartesian3.fromDegrees(customLongitude, customLatitude, 6000000)} /> */}
      </Viewer>
      <div style={toolBoxStyle}>aaa</div>

    </div>
  )
}